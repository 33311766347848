import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9e2dd6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "line" }
const _hoisted_2 = { class: "body w_1200" }

import LeftContent from "./components/LeftContent.vue";

export default {
  setup(__props) {


return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(LeftContent),
      _createElementVNode("main", null, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

}